.container {
    max-width: 1600px;
    min-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    background-color: #f9f9f9;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}

.header {
    background-color: black;
    color: white;
    padding: 20px;
    border-radius: 8px 8px 0 0;
    text-align: center;
}

.content {
    padding: 20px;
    background-color: white;
    border-radius: 0 0 8px 8px;
}

.infoGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    grid-auto-rows: minmax(100px, auto);
    gap: 20px;
}

.gridItem {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.gridItem strong {
    color: #333;
    margin-bottom: 10px;
}

.gridItem p {
    color: #555;
    margin: 0;
}

.span2 {
    grid-column: span 2;
}