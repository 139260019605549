.container {
    max-width: 1600px;
    margin: 0 auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f0f0f0;
  }
  
  .header {
    font-size: 2em;
    margin-bottom: 20px;
    color: #333;
  }
  
  .transactionsTable {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .transactionsTable th,
  .transactionsTable td {
    border: 1px solid #ddd;
    padding: 12px 15px;
    text-align: left;
  }
  
  .transactionsTable th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  .transactionsTable td {
    vertical-align: middle;
  }
  
  .verifyButton {
    width: 100%;
    background-color: #4caf50;
    color: white;
    padding: 10px 0;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    text-align: center;
    display: block;
  }
  
  .verifyButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  
  .verifyButton:hover:not(:disabled) {
    background-color: #45a049;
  }