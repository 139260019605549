body {
  font-family: 'Arial', sans-serif;
  background: #f0f2f5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  -webkit-font-smoothing: antialiased; 
  -moz-osx-font-smoothing: grayscale; 
}

.container {
  display: flex;
  flex-direction: row;
  max-width: 900px;
  width: 100%;
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.left-section {
  background: #000;
  color: #fff;
  padding: 30px;
  border-radius: 12px 0 0 12px;
  flex: 1;
}

.right-section {
  background: #fff;
  padding: 30px;
  border-radius: 0 12px 12px 0;
  flex: 1.5;
}

.form-header {
  font-size: 24px;
  padding-bottom: 10px;
  font-weight: bolder;
}
.form-subheader {
  font-size: 16px;
  border-bottom: 2px solid #444;
  padding-bottom: 10px;
  margin-bottom: 20px;
}
.form-input {
  margin-bottom: 15px;
}

.form-input-field {
  width: 100%;
  padding: 12px;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.form-input-field:focus {
  border-color: #007bff;
  outline: none;
}

.form-label {
  font-size: 14px;
  margin-bottom: 5px;
  color: #333;
}

.form-checkbox {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
}

.form-checkbox-input {
  width: 18px;
  height: 18px;
  margin-right: 10px;
}

.form-checkbox-label {
  font-size: 14px;
  color: #333;
}

.form-submit-container {
  display: flex;
  justify-content: flex-end;
}

.form-submit {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 12px 24px;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;

}



.form-submit-disabled {
  background: #ccc;
  color: #666;
  cursor: not-allowed;
}

.project-budget {
  font-size: 20px;
  color: #007bff;
  font-weight: bold;
  margin-top: 20px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
}

.loading-text {
  font-size: 24px;
  color: #007bff;
}