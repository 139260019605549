container {
    max-width: 1600px;
    margin: 20px auto;
    padding: 20px;
    background: #f9f9f9;
    border-radius: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.header {
    font-size: 1.5rem;
    margin-bottom: 20px;
    text-align: center;
}

.form {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 20px;
}

.formGroup {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.formLabel {
    margin-bottom: 5px;
}

.formControl {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.submitButton {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1rem;
}

.submitButton:hover {
    background-color: #45a049;
}